import React from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Form from "../../components/form";
import Newsletter from "../../components/newsletter.js";
import MapContainer from "../../components/map.js";

class ContactPage extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 4 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 0, duration: 4, delay: 1 });
  }

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Kontakt" />

        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>Kontakt</strong>
                  <br />{" "}
                  <span style={{ fontSize: ".5em" }}>
                    <b>ZAPRASZAMY</b>
                    <br /> DO NAWIĄZANIA
                    <br /> WSPÓŁPRACY
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  <b>Wszystkie potrzebne informacje kontaktowe</b> oraz
                  formularz znajdziecie Państwo poniżej. Po wysłaniu do nas
                  wiadomości, nasz zespół skontaktuje się z Państwem w dni
                  robocze do 24h.
                </p>
                <a href="#form" className="button rexer-button">
                  Formularz kontaktowy
                </a>
                <br />
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "0%",
                    marginBottom: "20px",
                    marginLeft: "-20px",
                    minWidth: "300px",
                    maxWidth: "500px"
                  }}
                  src="/img/telefon-dlon.png"
                  width="auto"
                  alt=""
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-175px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/a1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Zapraszamy również do <b>złożenia nam wizyty stacjonarnie</b>,
                  w siedzibie naszej Spółki we Wrocławiu.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#location">
                  Zobacz lokalizację
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            marginTop: "-5%",
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px"
            }}
          >
            <strong style={{ textTransform: "uppercase" }}>
              ZAPRASZAMY DO KONTAKTU POPRZEZ FORMULARZ KONTAKTOWY, MAILOWO,
              TELEFONICZNIE LUB OSOBIŚCIE W NASZEJ SIEDZIBIE.
            </strong>
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            Jesteśmy z Wrocławia, nasze biuro pracuje w godzinach od{" "}
            <strong>8:00</strong> do{" "}
            <strong>
              16:00<sup>*</sup>
            </strong>
            . Prosimy o wcześniejsze umówienie wizyty telefonicznie bądź
            mailowo.
            <br />
            <br />
            W okolicy znajduje się również Park Zachodni oraz Magnolia Park.
            Możecie Państwo miło spędzić czas na świeżym powietrzu oraz poddać
            sie zakupowemu szaleństwu w największej galerii handlowej na Dolnym
            Śląsku.
            <br />
            <sub>
              <sup>*</sup>W dni robocze od poniedziałku do piątku.
            </sub>
          </p>
        </div>

        <div
          className="columns"
          id="form"
          style={{
            padding: "50px 5%",
            marginBottom: "0px",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Form className="column is-half" style={{ maxHeight: "850px" }} />
          <div
            className="column is-half"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <div style={{ margin: "10%", marginBottom: "2.5%" }}>
              <img
                alt="Rexer logo - inżynieria środowiska"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "auto",
                  backgroundColor: "black",
                  padding: "5%",
                  borderRadius: "0px",
                  boxShadow: "10px 10px #00ff00"
                }}
                src="/img/logo-rexer.png"
              />
            </div>
            <br />
            <a
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                border: "1px solid #00ff00",
                color: "#333",
                minWidth: "220px"
              }}
              className="button standard-button is-size-5"
              href="tel:+48 71 339 81 56"
            >
              +48 71 339 81 56
            </a>
            <br />
            <br />
            <a
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                border: "1px solid #00ff00",
                color: "#333",
                minWidth: "220px"
              }}
              className="button standard-button is-size-5"
              href="mailto:biuro@rexer.pl"
            >
              biuro@rexer.pl
            </a>
            <br />
            <br />
            <br />
            <br />
            <p
              className="is-size-6"
              style={{ color: "#333", paddingTop: "0%" }}
            >
              <a className="is-size-4" href="#location">
                <b>REXER sp. z o.o.</b>
                <br />
                54-203 Wrocław, Polska <br />
                ul. Legnicka 55F/330
              </a>
            </p>{" "}
            <br />
            <br />
            <br />
            <img
              alt="Kontakt"
              loading="lazy"
              src="/img/grafika-404.png"
              style={{
                minWidth: "150px",
                maxWidth: "200px",
                marginTop: "30px"
              }}
              height="auto"
              width="auto"
            />
          </div>
        </div>
        <hr id="location" />
        <div
          className="is-bold"
          style={{ textAlign: "left", padding: "2.5% 3em" }}
        >
          <p className="is-size-3">
            Nasze biuro znajduje się na terenie Wrocławia.
            <br />
            <sub>
              Prosimy o wcześniejsze umówienie wizyty telefonicznie bądź
              mailowo.
            </sub>
          </p>
        </div>
        <br />
        <div
          className="cta-map"
          style={{ minHeight: "750px", position: "relative" }}
        >
          <MapContainer />
        </div>
        <Newsletter />
      </Layout>
    );
  }
}

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
